<template>
    <span>
    <a v-for="email in row.item.emails" :href="'mailto:'+email">{{ email }}</a>
    </span>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>